var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isPortfolioReady
    ? _c(
        "div",
        { key: `key_${_vm.title}_${_vm.key}` },
        [
          _c("StudentSubHeader", {
            attrs: { "show-marking-period-picker": false },
          }),
          _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-3 col-lg-5" },
                  [
                    _c("StudentHeaderPortlet", {
                      attrs: { "display-wide": false },
                    }),
                    _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c(
                          "div",
                          { staticClass: "kt-widget1 kt-widget1--fit" },
                          [
                            _c("div", { staticClass: "kt-widget1__item" }, [
                              _vm._m(0),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "kt-widget1__number kt-font-brand",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.yearToDate.absent.instances) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "kt-widget1__item" }, [
                              _vm._m(1),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "kt-widget1__number kt-font-danger",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.yearToDate.late.instances) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "kt-widget1__item" }, [
                              _vm._m(2),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "kt-widget1__number kt-font-success",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.yearToDate.excused.instances) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-xl-9 col-lg-7" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.attendanceEvaluations.length
                      ? _c(
                          "div",
                          { staticClass: "d-lg-none d-xl-block col-xl-6" },
                          [
                            _c("AttendanceHistory", {
                              attrs: { evaluations: _vm.attendanceEvaluations },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        class: _vm.attendanceEvaluations.length
                          ? "col-lg-12 col-xl-6"
                          : "col-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kt-portlet kt-portlet--height-fluid",
                          },
                          [
                            _vm._m(3),
                            _c("div", { staticClass: "kt-portlet__body" }, [
                              _vm.selectedMonth
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "py-2",
                                      staticStyle: {
                                        "max-width": "340px",
                                        "text-align": "center",
                                        margin: "auto",
                                      },
                                    },
                                    [
                                      _c("b-calendar", {
                                        key: `${_vm.month}_${_vm.year}`,
                                        staticClass: "pl-2 mt-2",
                                        attrs: {
                                          "date-info-fn": _vm.dateClass,
                                          "label-help": "",
                                          "show-decade-nav": false,
                                          "no-key-nav": true,
                                          "hide-header": true,
                                          readonly: true,
                                          "initial-date": _vm.date,
                                          min: _vm.calendarOptions.validRange
                                            .start,
                                          max: _vm.calendarOptions.validRange
                                            .end,
                                          block: "",
                                        },
                                        on: { context: _vm.onContext },
                                      }),
                                      _vm._m(4),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-xl-12" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      { staticClass: "kt-portlet__body" },
                      [
                        _vm.ready
                          ? _c("FullCalendar", {
                              ref: "calendar",
                              attrs: { options: _vm.calendarOptions },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [_vm._v(" Absences ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [_vm._v(" Late ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [_vm._v(" Excused ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" School Attendance "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "p-3 row" }, [
      _c("div", { staticClass: "col-lg-3 col-md-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#baecdd" },
        }),
        _c("small", { staticClass: "text-muted d-block" }, [_vm._v("Present")]),
      ]),
      _c("div", { staticClass: "col-lg-3 col-md-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#fec8da" },
        }),
        _c("small", { staticClass: "text-muted d-block" }, [_vm._v("Absent")]),
      ]),
      _c("div", { staticClass: "col-lg-3 col-md-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#ffebc1" },
        }),
        _c("small", { staticClass: "text-muted d-block" }, [_vm._v("Late")]),
      ]),
      _c("div", { staticClass: "col-lg-3 col-md-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#cfd9f9" },
        }),
        _c("small", { staticClass: "text-muted d-block" }, [_vm._v("Excused")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Course Attendance "),
        ]),
      ]),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }