<template>
<div v-if="isPortfolioReady" :key="`key_${title}_${key}`">
    <StudentSubHeader :show-marking-period-picker="false" />
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-xl-3 col-lg-5">
                <StudentHeaderPortlet :display-wide="false" />
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div class="kt-widget1 kt-widget1--fit">
                            <div class="kt-widget1__item">
                                <div class="kt-widget1__info">
                                    <h3 class="kt-widget1__title">
                                        Absences
                                    </h3>
                                    <!-- <span class="kt-widget1__desc">
                                        20% of all days this semester
                                    </span> -->
                                </div>
                                <span class="kt-widget1__number kt-font-brand">
                                    {{ yearToDate.absent.instances }}
                                </span>
                            </div>
                            <div class="kt-widget1__item">
                                <div class="kt-widget1__info">
                                    <h3 class="kt-widget1__title">
                                        Late
                                    </h3>
                                    <!-- <span class="kt-widget1__desc">
                                        10% of all days this semester
                                    </span> -->
                                </div>
                                <span class="kt-widget1__number kt-font-danger">
                                    {{ yearToDate.late.instances }}
                                </span>
                            </div>
                            <div class="kt-widget1__item">
                                <div class="kt-widget1__info">
                                    <h3 class="kt-widget1__title">
                                        Excused
                                    </h3>
                                    <!-- <span class="kt-widget1__desc">
                                        0% of all days this semester
                                    </span> -->
                                </div>
                                <span class="kt-widget1__number kt-font-success">
                                    {{ yearToDate.excused.instances }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-7">
                <div class="row">
                    <div v-if="attendanceEvaluations.length" class="d-lg-none d-xl-block col-xl-6">
                        <AttendanceHistory :evaluations="attendanceEvaluations" />
                    </div>
                    <div :class="attendanceEvaluations.length ? 'col-lg-12 col-xl-6': 'col-12'">
                        <div class="kt-portlet kt-portlet--height-fluid">
                            <div class="kt-portlet__head">
                                <div class="kt-portlet__head-label">
                                    <h3 class="kt-portlet__head-title">
                                        School Attendance
                                    </h3>
                                </div>
                            </div>
                            <div class="kt-portlet__body">
                                <div
                                    v-if="selectedMonth"
                                    class="py-2"
                                    style="max-width: 340px; text-align: center; margin: auto;"
                                >
                                    <b-calendar
                                        :key="`${month}_${year}`"
                                        :date-info-fn="dateClass"
                                        class="pl-2 mt-2"
                                        label-help=""
                                        :show-decade-nav="false"
                                        :no-key-nav="true"
                                        :hide-header="true"
                                        :readonly="true"
                                        :initial-date="date"
                                        :min="calendarOptions.validRange.start"
                                        :max="calendarOptions.validRange.end"

                                        block
                                        @context="onContext"
                                    />

                                    <div class="p-3 row">
                                        <div class="col-lg-3 col-md-6">
                                            <span
                                                class="colorSwatch"
                                                style="background-color: #baecdd"
                                            />
                                            <small class="text-muted d-block">Present</small>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <span
                                                class="colorSwatch"
                                                style="background-color: #fec8da"
                                            />
                                            <small class="text-muted d-block">Absent</small>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <span
                                                class="colorSwatch"
                                                style="background-color: #ffebc1"
                                            />
                                            <small class="text-muted d-block">Late</small>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <span
                                                class="colorSwatch"
                                                style="background-color: #cfd9f9"
                                            />
                                            <small class="text-muted d-block">Excused</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Course Attendance
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <!-- <div class="pt-2 d-flex">
                                <label class="pt-2 pr-3 mb-0">Show all</label>
                                <div>
                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked="checked"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <FullCalendar
                            v-if="ready"
                            ref="calendar"
                            :options="calendarOptions"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

// import async from 'async';

import Vue from 'vue';
import moment from 'moment';
import portfolioMixins from '../store/mixins/portfolioMixins';
import studentMixins from '../store/mixins/studentMixins';
// import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
// import averagingMixins from '../store/mixins/averagingMixins';
import StudentSubHeader from './StudentSubHeader.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import AttendanceHistory from './studentEvaluation/AttendanceHistory.vue';

import Types from '../store/Types';

export default Vue.extend({
    name: 'StudentAttendance',
    components: {
        StudentHeaderPortlet,
        StudentSubHeader,
        AttendanceHistory,
        FullCalendar,
    },
    mixins: [
        portfolioMixins,
        studentMixins,
        courseMixins,
    ],
    data() {
        return {
            key: 0,
            ready: false,
            schoolDayCalendar: {},
            calendarOptions: {
                contentHeight: 'auto',
                // selectable: true,
                plugins: [
                    dayGridPlugin,
                    bootstrapPlugin,
                    listPlugin,
                    interactionPlugin,
                ],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                },
                validRange: {
                    start: null,
                    end: null,
                },
                themeSystem: 'bootstrap',
                weekends: false,
                // dateClick: this.handleDateClick,
                events: [],
            },
        };
    },
    computed: {
        selectedMonth: {
            get() {
                const { startDate } = this.$store.state.database.attendance;
                if (!startDate) return null;
                return moment(startDate, 'YYYY-MM-DD').format('YYYY-MM');
            },
            set(startDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_START_DATE, startDate);
            },
        },
        selectedDate: {
            get() {
                const { startDate } = this.$store.state.database.attendance;
                return startDate || null;
            },
            set(startDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_START_DATE, startDate);
            },
        },
        user() {
            return this.$store.state.user;
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean((this.$store.state.database.cache).find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        attendanceEvaluations() {
            return this.$_studentMixins_getStudentBiographicals(this.student.studentId)
                .filter((e) => e.bioCategory == 'Attendance');
        },
        studentCourses() {
            const { studentEnrollmentId, isPortfolioReady } = this;
            if (!isPortfolioReady) return [];
            return [...this.$_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId)]
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
        },
        date() {
            if (!this.selectedMonth) return null;
            const date = `${this.selectedMonth}-01`;
            return date;
        },
        year() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('YYYY');
        },
        month() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('MM');
        },
        monthName() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('MMMM');
        },
        studentAttendance() {
            const { studentEnrollmentId } = this;
            const { schoolAttendance } = this.$store.state.database;
            return schoolAttendance.filter((row) => row.studentEnrollmentId == studentEnrollmentId);
        },
        yearToDate() {
            const {
                studentAttendance,
                createPercentage,
                formatInstances,
            } = this;

            const totals = {
                absent: 0,
                present: 0,
                late: 0,
                excused: 0,
                denominator: 0,
            };

            const absent = studentAttendance.filter((r) => r.attendanceState == 'Absent');
            const present = studentAttendance.filter((r) => r.attendanceState == 'Present');
            const late = studentAttendance.filter((r) => r.attendanceState == 'Late');
            const excused = studentAttendance.filter((r) => r.attendanceState == 'Excused');
            const denominator = studentAttendance.length;

            totals.absent += absent.length;
            totals.present += present.length;
            totals.late += late.length;
            totals.excused += excused.length;
            totals.denominator += denominator;

            return {
                absent: { instances: formatInstances(totals.absent), percentage: createPercentage(totals.absent, totals.denominator) },
                present: { instances: formatInstances(totals.present), percentage: createPercentage(totals.present, totals.denominator) },
                late: { instances: formatInstances(totals.late), percentage: createPercentage(totals.late, totals.denominator) },
                excused: { instances: formatInstances(totals.excused), percentage: createPercentage(totals.excused, totals.denominator) },
            };
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
    },
    watch: {
        studentEnrollmentId() {
            this.key += 1;
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
        forceStudentView() {
            this.key += 1;
        },
        databaseIsReady: {
            handler() {
                this.populate();
            },
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        onContext(ctx) {
            this.selectedMonth = `${ctx.activeYMD.substring(0, 7)}`;
        },
        rowSelected(item) {
            this.monthName = `${item.year}-${item.month}`;
        },
        dateClass(dateString) {
            // color the date background
            const v = this;
            const { studentAttendance } = v;
            const row = studentAttendance.find((r) => r.attendanceDate == dateString);
            if (!row) return null;

            const present = row.attendanceState == 'Present';
            const absent = row.attendanceState == 'Absent';
            const late = row.attendanceState == 'Late';
            const excused = row.attendanceState == 'Excused';

            if (present) return 'table-success';
            if (absent) return 'table-danger';
            if (late) return 'table-warning';
            if (excused) return 'table-info';
            return null;
        },
        formatPercentage(p) {
            if (!p) return '-';
            return `${p}%`;
        },
        formatInstances(total) {
            return total;
            // if (!total) return '';
            // if (total == 1) return '1 time';
            // return `${total} times`;
        },
        getCalendarEvents() {
            const { formattedAttendance } = this.$store.state.database.attendance;
            const { studentCourses } = this;
            const events = [];

            const days = Object.keys(formattedAttendance);
            // console.log('formattedAttendance2', state.database.attendance.formattedAttendance);
            // console.log('2024-05-01', formattedAttendance['2024-05-01']);
            days.forEach((schoolDayCalendarDate) => {
                const item = formattedAttendance[schoolDayCalendarDate];

                if (item.nonSchoolDay) {
                    events.push({
                        title: 'Non-School Day',
                        start: schoolDayCalendarDate,
                        color: '#6c757d',
                    });

                    events.push({
                        start: schoolDayCalendarDate,
                        end: schoolDayCalendarDate,
                        display: 'background',
                        color: '#6c757d',
                    });
                } else {
                    const { attendanceState } = item.schoolAttendance;
                    let color = null;

                    if (attendanceState == 'Absent') {
                        color = '#fd397a';
                    } else if (attendanceState == 'Late') {
                        color = '#ffb822';
                    } else if (attendanceState == 'Excused') {
                        color = '#0f88ef';
                    } else if (attendanceState == 'Present') {
                        color = '#0abb87';
                    }

                    if (color) {
                        events.push({
                            title: attendanceState,
                            start: schoolDayCalendarDate,
                            color,
                        });
                        events.push({
                            start: schoolDayCalendarDate,
                            end: schoolDayCalendarDate,
                            display: 'background',
                            color,
                        });
                    }

                    studentCourses.forEach((course) => {
                        const { courseSectionId, name } = course;
                        const attendance = item.courseAttendance.find((r) => r.courseSectionId == courseSectionId);
                        const state = attendance ? attendance.attendanceState : 'Not taken';
                        let eventColor = '#6c757d';
                        if (state == 'Absent') {
                            eventColor = '#fd397a';
                        } else if (state == 'Late') {
                            eventColor = '#fd7e14';
                        } else if (state == 'Excused') {
                            eventColor = '#0f88ef';
                        } else if (state == 'Present') {
                            eventColor = '#0abb87';
                        }

                        events.push({
                            title: `${name}: ${state}`,
                            start: schoolDayCalendarDate,
                            color: eventColor,
                        });
                    });
                }
            });
            return events;
        },
        populate() {
            const v = this;
            if (!this.databaseIsReady) return;
            const { studentEnrollmentId } = this;
            if (!studentEnrollmentId) return;

            const { termStart, termEnd } = this.user.school;
            this.calendarOptions.validRange.start = termStart;
            this.calendarOptions.validRange.end = termEnd;

            if (!this.selectedMonth) {
                this.selectedMonth = moment().startOf('month').format('YYYY-MM-DD');
            }

            this.$store.dispatch(Types.actions.GET_ATTENDANCE_FOR_STUDENT, {
                studentEnrollmentId,
                callback(err) {
                    if (err) return v.showError(err);
                    setTimeout(() => {
                        v.calendarOptions.events = v.getCalendarEvents();
                        v.ready = true;
                    }, 10);
                },
            });
        },
    },
});

</script>
